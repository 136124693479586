import React from 'react';
import styled from 'styled-components';
import { OtColors } from 'util/OtColors';
import { IconType, PromoConst } from 'util/Constants';
import {
	CompanionNoColor,
	SvgWheelchairNoColor,
} from 'components/common/icons/SvgIcons';
import { useSelector } from 'react-redux';
import {
	selectSelectedPromo,
	selectSelectedTicketRule,
} from '../../../slices/selectedMultiselectOptions';
import { ReduxStore } from 'reducers/configureStore';

const Legend = styled.li`
	padding-right: 15px !important;
	opacity: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	.legendContent {
		display: flex;
		flex-direction: column;

		.seatInfo {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.seatNameWrapper {
				display: flex;
				align-items: center;

				&:before {
					content: '';
					display: block;
					margin-right: 8px;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					background-color: currentColor;
					flex-shrink: 0;
				}

				.seatName {
					color: ${OtColors.black} !important;
					font-size: 14px;
					font-weight: 600;
				}
			}

			.seatPrice {
				color: ${OtColors.black} !important;
				font-size: 12px;
				margin-top: 4px;
				margin-left: 24px;
			}
		}
	}
`;

const PriceLevels = () => {
	const priceLevels =
		useSelector((state) => state.priceLevel.priceLevels) || {};
	const selectedTicketTypeName =
		useSelector(
			(state) => state.selectedMultiSelectOptions.selectedTicketTypeName,
		) ?? '';
	const selectedPromoId = useSelector(selectSelectedPromo)?.promotionId ?? '';

	const isValidPriceLevels = (levels) =>
		typeof levels === 'object' && levels !== null && !Array.isArray(levels);

	const getSelectedTicketType = (ticketTypes, ticketTypeName) =>
		ticketTypeName
			? ticketTypes.find((ticket) => ticket.name === ticketTypeName)
			: null;

	const getDisplayPrice = (ticketType) => {
		const state = ReduxStore.getState();
		const selectedTicketRule = selectSelectedTicketRule(
			ticketType.ticketTypeId,
		)(state);

		const priceIncludingFees =
			ticketType.price +
			ticketType.convenienceFeeBO +
			ticketType.facilityFeePrice;

		if (!selectedPromoId || selectedPromoId === PromoConst.FULL_PRICE_ID) {
			return priceIncludingFees;
		}
		return selectedTicketRule?.price ?? priceIncludingFees;
	};

	const createPriceLegend = (priceLevel, index) => {
		const { color, name, type, ticketTypes } = priceLevel;
		const selectedTicket = getSelectedTicketType(
			ticketTypes,
			selectedTicketTypeName,
		);

		if (
			!selectedTicket ||
			(selectedTicket.type === 2 &&
				(!selectedPromoId || selectedPromoId === PromoConst.FULL_PRICE_ID))
		) {
			return null;
		}

		const displayPrice = getDisplayPrice(selectedTicket);
		const priceLabel = `$${displayPrice.toFixed(2)}`;
		const icon =
			type === IconType.HANDICAP ? (
				<SvgWheelchairNoColor />
			) : (
				<CompanionNoColor />
			);

		return (
			<Legend key={index} className='legend showcased' style={{ color }}>
				<div className='legendContent'>
					<div className='seatInfo'>
						<div className='seatNameWrapper'>
							{type === 'NORMAL' ? (
								<span className='seatName'>{name}</span>
							) : (
								icon
							)}
						</div>
						<span className='seatPrice'>{priceLabel}</span>
					</div>
				</div>
			</Legend>
		);
	};

	if (!isValidPriceLevels(priceLevels)) {
		console.error('priceLevels error:', priceLevels);
		return null;
	}

	return Object.values(priceLevels)
		.map((priceLevel, index) => createPriceLegend(priceLevel, index))
		.filter(Boolean);
};

export default PriceLevels;
