import React, { useState, useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import SeatingChart from 'components/seatingChart/seatSelection/SeatingChart';
import { ModalContainer } from 'components/common/modals/ModalContainer';
import Flex from 'components/common/containers/Flex';
import { LoadingIcon } from 'components/utils/LoadingView';
import { ChartConst, Layout } from 'util/Constants';
import 'sass/components/seatingChart/SeatingChart.scss';
import seatingChartReducer from 'stores/calendar/SeatingChartStore';
import { useParams } from 'react-router-dom';
import SelectSeat from 'components/seatingChart/seatSelection/seat/SelectSeat';
import WebSocketClient from './websocket/WebSocketClient';
import { selectTemporarySeat } from 'components/seatingChart/actions';
import 'sass/_global.scss';
import { getAllPromos } from './apis/promotions';
import { ReduxStore } from 'reducers/configureStore';
import { setPromos as setPromosRedux } from './slices/promoSlice';
import { selectFeatureFlag, setFeatureFlags as setFeatureFlagsRedux } from './slices/featureFlagSlice';
import { setPriceLevels } from './slices/priceLevelSlice';
import LandscapeMessage from './components/common/messages/LandscapeMessage';
import { LoadErrorMessageContext } from './components/seatingChart/context/LoadErrorMessageContext';
import { addToCart, unSelectSeat} from 'apis/cartWebSocketClient';
import { MultiselectToolbar } from 'components/seatingChart/MultiSelect/MultiselectToolbar';
import { patronHoldLoad } from './apis/cartWebSocketClient';
import { generateSeatObjectForAddToCart } from 'components/utils/handlers/seatingChartHandlers';
import {
	selectSelectedPromo,
	selectSelectedTicketRule,
	selectSelectedTicketType
} from "./slices/selectedMultiselectOptions";

const { CHART_PADDING, TOOLBAR_HEIGHT } = ChartConst;

const App = (props) => {
	const { clientId, performanceId, productionId } = useParams();
	const [hasAccSeating, setHasAccSeating] = useState(false);
	const [seatingChart, setSeatingChart] = useState(
		props.seatingChart?.seatingChart || {},
	);
	const [selectedSeats, setSelectedSeats] = useState({});

	const [allSelectedSeats, setAllSelectedSeats] = useState({});
	const [allSelectedSeatsArray, setAllSelectedSeatsArray] = useState([]);
	const [coordinates, setCoordinates] = useState({});

	const [, setLoading] = useState(true);
	const [, setError] = useState(null);
	const [currencySymbol] = useState('$');
	const [featureFlags, setFeatureFlags] = useState([]);
	const [promos, setPromos] = useState([]);

	const { errorMessage, setErrorMessage } = useContext(LoadErrorMessageContext);

	const selectedMultiSelectOptions = useSelector((state) => state.selectedMultiSelectOptions);
	const selectedPromoId = selectedMultiSelectOptions.selectedPromoId;
	const selectedTicketTypeName = selectedMultiSelectOptions.selectedTicketTypeName;
	const isMultiSelectEnabled = useSelector(selectFeatureFlag('is-multi-select-enabled'));
	const isPatronHoldSeatSelectEnabled = useSelector(selectFeatureFlag('is-patron-hold-seats-select-enabled'));

	const handleWebSocketMessage = (message) => {
		if (!message.seatIds || message.seatIds.length === 0) {
			onClosePopup();
			updateSelectedSeats();
		}

		if (message.seatIds && message.seatIds.length > 0) {
			message.seatIds.forEach((seatId) => {
				setSelectedSeats((prevState) => {
					const newState = { ...prevState };
					delete newState[seatId];
					return newState;
				});
				
				setAllSelectedSeatsArray((prevArray) =>
					prevArray.filter((seat) => seat.id !== seatId),
				);
			});
		}
	};

	const handleResize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		});
	};

	const fetchSeatingChart = async (featureFlags) => {
		try {
			const isSeatingChartAPIEnabled = featureFlags.find(
				(flagObj) => flagObj.flag === 'is-seating-chart-api-enabled',
			)
				? featureFlags.find(
						(flagObj) => flagObj.flag === 'is-seating-chart-api-enabled',
					).value
				: false;
			const seatingChart = await seatingChartReducer.fetchSeatingChartData(
				performanceId,
				clientId,
				true,
				setErrorMessage,
				isSeatingChartAPIEnabled,
			);
			setSeatingChart(seatingChart);
			ReduxStore.dispatch(setPriceLevels(seatingChart.priceLevels));
			setLoading(false);
		} catch (error) {
			setError(error);
			setLoading(false);
		}
	};

	const fetchPromos = async () => {
		try {
			const promos = await getAllPromos(performanceId);
			setPromos(promos);
			ReduxStore.dispatch(setPromosRedux(promoArrayToObject(promos)));
		} catch (error) {
			setError(error);
		}
	};

	const handleMessage = (event) => {
		if (event.data && event.data.type === 'FEATURE_FLAGS') {
			const featureFlags = event.data.data;
			setFeatureFlags(featureFlags);
			ReduxStore.dispatch(
				setFeatureFlagsRedux(featureFlagsToObject(featureFlags)),
			);
			fetchSeatingChart(featureFlags);
			fetchPromos();
		}
	};

	useEffect(() => {
		setHasAccSeating(true);
		window.addEventListener('message', handleMessage);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('message', handleMessage);
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const onMultiSelect = async (data) => {
		const state = ReduxStore.getState()
		const promotion = selectSelectedPromo(state)
		const selectedTicketType = selectSelectedTicketType(data.priceLevel.id)(state)
		const selectedTicketRule = selectSelectedTicketRule(selectedTicketType.ticketTypeId)(state)
		const seatObj = {
			sectionId: data?.sectionId,
			selectedTicketTypeId: selectedTicketType.ticketTypeId,
			selectedTicketRuleId: selectedTicketRule?.id ? selectedTicketRule?.id : null,
			promotionPrice: selectedTicketRule?.price || 0,
			promotionBOFee: selectedTicketRule?.convenienceFeeBO || 0,
			promotionName:  promotion?.promotionName === 'Full price' ? null : promotion?.promotionName || null,
			name: data?.sectionName,
			promotionCode:
				promotion?.promoCode ? promotion?.promoCode : null,
			promotionId: promotion?.promotionId === 'Full price' ? null : promotion?.promotionId || null,
			seats: data.id,
			performanceId: performanceId,
			seatType: data?.priceLevel?.type,
			seatTypeInitial: 'available',
			type: 'MSG',
			number: data.number,
			row: data.row,
			rowNumber: data.rowNumber,
			selectedTicketName: selectedTicketType.name,
		}

		try {
			const result = await addToCart(seatObj)
			if (result) {
				setSelectedSeats((prevState) => {
					return { ...prevState, [data.id]: data };
				});
				props.onSelectTemporarySeat({});
			}
		} catch (error) {
			console.error('Error handleAddToCart:', error)
		}
	}

	const onPatronHoldSeatSelect = async (data) => {
		try {
			const ticket = data?.ticket;
			if (!ticket) return;

			const patronHoldSeats = seatingChartReducer.extractSeatsByPatronIdAndSection(
				seatingChart, 
				ticket.patron.id, 
				data.sectionId
			);

			const seatObjects = patronHoldSeats.map(seat => 
				processPatronHoldSeatForAddToCart(seat, selectedTicketTypeName, selectedPromoId)
			);

			const result = await patronHoldLoad(ticket, seatObjects);
			if(!result) return;

			setSelectedSeats(prevState =>({
				...prevState,
				...Object.fromEntries(patronHoldSeats.map(seat => [seat.id, seat]))
			}));

			props.onSelectTemporarySeat({});
		} catch (error) {
			console.error('Error validateHoldAndSendPatronLoadMessage:', error)
		}
	}

	const processPatronHoldSeatForAddToCart = (seat, ticketTypeName, promoId) => {
		const ticketTypes = seat.priceLevel.ticketTypes;
    	const ticketType = ticketTypes.find(ticketType => ticketType.name === ticketTypeName);
		let promotionSelected = null;
		if (promoId !== 'Full price') {
			const promotion = promos.find(promotion => promotion.promotionId === promoId);
			const ticketRule = promotion.ticketRules.find(ticketRule => ticketRule.ticketTypeId === ticketType.ticketTypeId);
			promotionSelected = {
				name: promotion.promotionName,
				promotionCode: promotion.promoCode,
				promotionId: promotion.promotionId,
				ticketRuleId: ticketRule.id,
				price: ticketRule.price,
				convenienceFeeBO: ticketRule.consumerFeeBO,
			}
		}		
		let ticket = {
			ticketTypeId: ticketType.ticketTypeId,
			name: ticketType.name,
			promotionId: promotionSelected ? promotionSelected.promotionId : null,
			promotionCode: promotionSelected ? promotionSelected.promotionCode : null,
			selectedTicketRuleId: promotionSelected ? promotionSelected.ticketRuleId : null,
		}
		const ticketForPricing = {
			promotionCode: null,
			promotionId: null,
		};
		return generateSeatObjectForAddToCart(seat, ticket, promotionSelected, ticketForPricing, performanceId);
	}

	const onSeatSelect = (data, _seat, coordinates) => {
		let isPatronHoldSeatSelected = data.ticket?.patron && "PATN" == data.ticket?.statusInitial;
		if (isMultiSelectEnabled) {
			if(isPatronHoldSeatSelected) {
				if(!isPatronHoldSeatSelectEnabled) return;
				onPatronHoldSeatSelect(data)
			} else {
				onMultiSelect(data);
			}
			return
		}
		let allSelectedSeats = Object.assign(
			{},
			{ ...selectedSeats },
			{ [data.id]: data },
		);
		setAllSelectedSeatsArray((prevArray) => [...prevArray, data]);
		setAllSelectedSeats(allSelectedSeats);
		setCoordinates(coordinates);
	};

	const updateSelectedSeats = () => {
		setSelectedSeats(allSelectedSeats);
	};

	const onClosePopup = () => {
		setAllSelectedSeats({});
		setCoordinates([0, 0]);

		setAllSelectedSeatsArray([]);
		props.onSelectTemporarySeat({});
	};

	const onSeatUnselect = async (seats) => {
		const seatMessage = {
			type: 'removeSeat',
			seatIds: [seats?.id],
			performanceId: performanceId,
		};

		const response = await unSelectSeat(seatMessage);
		if (response) {
			setSelectedSeats((prevState) => {
				const newState = { ...prevState };
				delete newState[seats.id];
				return newState;
			});
		}
	};

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const renderNoTicketsAvailableMessage = () => {
		const calculatedSCViewPortSize = [
			windowSize.width,
			windowSize.height - Layout.min_navbar_height,
		];

		const reducedWidth = calculatedSCViewPortSize[0] * 0.95;
		const reducedHeight = calculatedSCViewPortSize[1] * 0.95;

		if (errorMessage) {
			return (
				<LandscapeMessage
					toggleMessage={true}
					isPermanent={true}
					message={errorMessage}
					width={reducedWidth}
					height={reducedHeight}
				/>
			);
		}
	};



	const renderSeatingChart = () => {
		if (Object.keys(seatingChart).length === 0) {
			return (
				<ModalContainer key='modalContainer' clear>
					<LoadingIcon key='loading' size='lg' />
				</ModalContainer>
			);
		}

		const quantity = selectedSeats && Object.keys(selectedSeats).length;
		const calculatedSCViewPortSize = [
			window.innerWidth,
			window.innerHeight - Layout.min_navbar_height,
		];

		const selectedSeat =
			allSelectedSeatsArray[allSelectedSeatsArray.length - 1];

		return (
			<React.Fragment>
				<Flex
					className='containerSeatingChart'
					columns={2}
					fit={true}
					isDisabled={false}
					stretch
				>
					<SeatingChart
						key='seating_chart'
						clientId={clientId}
						data={seatingChart}
						disableOldWorkFlow={false}
						displayPriceLevelFilter={false}
						displayZoomButtons={true}
						hasAccSeating={hasAccSeating}
						onClickBack={() => {}}
						isDragging={() => {}}
						onSeatSelect={onSeatSelect}
						onSeatUnselect={
							isMultiSelectEnabled ? onSeatUnselect : onClosePopup
						}
						onSelectCartSeat={() => {}}
						performanceId={performanceId}
						productionId={productionId}
						productionSeatSelectionMethod={null}
						quantity={quantity}
						query={null}
						selectedSeats={selectedSeats}
						hidePopup={() => {}}
						noToolBar={true}
						isSmallDevice={false}
						isMobile={false}
						padding={{
							top: TOOLBAR_HEIGHT,
							right: CHART_PADDING,
							bottom: CHART_PADDING,
							left: CHART_PADDING,
						}}
						viewPortSize={calculatedSCViewPortSize}
						currencySymbol={currencySymbol}
						featureFlags={featureFlags}
					/>
				</Flex>
				{Object.keys(allSelectedSeats).length > 0 && !isMultiSelectEnabled && (
					<SelectSeat
						coordinates={coordinates}
						selectedSeat={selectedSeat}
						promoName=''
						promos={promos}
						onClosePopup={onClosePopup}
						performanceId={performanceId}
						setSelectedSeats={updateSelectedSeats}
					/>
				)}
			</React.Fragment>
		);
	};

	return (
		<div
			className='ot_performanceSeatingChartView'
			style={{ overflow: 'hidden' }}
		>
			{isMultiSelectEnabled && <MultiselectToolbar />}
			<React.Fragment>
				{errorMessage
					? renderNoTicketsAvailableMessage()
					: renderSeatingChart()}
				<WebSocketClient onMessage={handleWebSocketMessage} />
			</React.Fragment>
		</div>
	);
};

const mapDispatchToProps = (dispatch) => ({
	onSelectTemporarySeat: (seat) => dispatch(selectTemporarySeat(seat)),
});

export default connect(null, mapDispatchToProps)(App);

// converts an array of promo objects to a promo object with the promoId as the key
function promoArrayToObject(promos) {
	const promoObj = {};
	promos.forEach((promo) => {
		promoObj[promo.promotionId] = promo;
	});
	return promoObj;
}

// convert an array of feature flags to a map
function featureFlagsToObject(featureFlags) {
	const featureFlagsObj = {};
	featureFlags.forEach((flag) => {
		featureFlagsObj[flag.flag] = flag.value;
	});
	return featureFlagsObj;
}
